import React from "react";

const BookPage = () => {
  return (
    <section id="about" className="bg-white py-16">
     
      <div  className="container mx-auto px-4">

      <iframe title="booking-widget" width="100%" height="1000" src="https://ehr.charmtracker.com/publicCal.sas?method=getCal&digest=edb5a5bb29aedb5af74f6b58ec7d0feb9108afc5b06b00d2cefe13bb894025c13da030e7f293979a5377deefbdc0f28bc2fe0580a6189219" frameborder="0"></iframe>
      </div>
    </section>
  );
};

export default BookPage;
