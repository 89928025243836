import React, { useEffect, useRef, useState } from 'react';
import { ArrowRight } from 'lucide-react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

function ThreeScene() {
  const mountRef = useRef(null);
  const [loadingError, setLoadingError] = useState(null);
  const [loadingProgress, setLoadingProgress] = useState(0);

  useEffect(() => {
    let model;
    let frameId;

    // Scene setup
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    camera.position.z = 5;

    camera.position.set(0, 0, 200); // Moved camera further back
    camera.lookAt(0, 0, 0);

    // Renderer setup
    const renderer = new THREE.WebGLRenderer({ 
      antialias: true,
      alpha: true 
    });
    renderer.setClearColor(0xffffff, 0); // Transparent background

    // Get container dimensions
    const container = mountRef.current;
    const width = container.clientWidth;
    const height = container.clientHeight;
    renderer.setSize(width, height);
    container.appendChild(renderer.domElement);

    // Lighting
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight.position.set(5, 5, 5);
    scene.add(directionalLight);

    // Controls
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.05;
    controls.enableZoom = false;

    // Add loading indicator cube
    const geometry = new THREE.BoxGeometry(1, 1, 1);
    const material = new THREE.MeshPhongMaterial({ color: 0x6d28d9 });
    const cube = new THREE.Mesh(geometry, material);
    scene.add(cube);

    // Model loading
    const loader = new GLTFLoader();
    const modelPath = '../assets/paper_plane/scene.gltf'; // Make sure this path matches your public directory structure

    loader.load(
      modelPath,
      (gltf) => {
        model = gltf.scene;
        scene.remove(cube); // Remove loading cube

        model.scale.set(0.5, 1, 1);
        model.position.set(0, 0, 0);

        // Center the model
        new THREE.Box3()
          .setFromObject(model)
          .getCenter(model.position)
          .multiplyScalar(-1);

        // Apply material
        model.traverse((child) => {
          if (child.isMesh) {
            child.material = new THREE.MeshStandardMaterial({
              color: 0x78bae7, // Purple color
              metalness: 0.3,
              roughness: 0.7
            });
          }
        });

        scene.add(model);
      },
      (progress) => {
        if (progress.total > 0) {
          const percentComplete = (progress.loaded / progress.total) * 100;
          setLoadingProgress(Math.round(percentComplete));
        }
      },
      (error) => {
        console.error('Error loading model:', error);
        setLoadingError(error.message);
      }
    );

    // Animation
    const animate = () => {
      frameId = requestAnimationFrame(animate);

      if (cube) {
        cube.rotation.x += 0.01;
        cube.rotation.y += 0.01;
      }

      if (model) {
        model.position.y = Math.sin(Date.now() * 0.001) * 0.2;
        model.rotation.y += 0.005;
      }

      controls.update();
      renderer.render(scene, camera);
    };

    animate();

    // Handle resize
    const handleResize = () => {
      const width = container.clientWidth;
      const height = container.clientHeight;

      camera.aspect = width / height;
      camera.updateProjectionMatrix();
      renderer.setSize(width, height);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
      cancelAnimationFrame(frameId);
      if (mountRef.current && renderer.domElement) {
        mountRef.current.removeChild(renderer.domElement);
      }
      controls.dispose();
      renderer.dispose();
    };
  }, []);

  return (
    <div className="relative w-full h-full">
      <div
        ref={mountRef}
        className="absolute inset-0"
      />
      {loadingError && (
        <div className="absolute inset-0 flex items-center justify-center text-red-500">
          Error: {loadingError}
        </div>
      )}
      
    </div>
  );
}

const HeroSection = () => {
  return (
    <div className="bg-white relative overflow-hidden">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
          {/* Left Column - Content */}
          <div className="space-y-8 relative z-10">
            <div className="space-y-4">
              <h1 className="text-4xl md:text-5xl font-bold text-gray-900 leading-tight">
                Your Journey to Better Mental Health Starts Here
              </h1>
              <p className="text-lg md:text-xl text-gray-600 leading-relaxed">
                At Transitions Behavioral Health, we provide compassionate,
                evidence-based mental health services tailored to your unique
                needs. Our experienced team is here to support you every step of
                the way.
              </p>
            </div>

            <div className="space-y-4 md:space-y-0 md:space-x-4 flex flex-col md:flex-row">
              <button
                onClick={() => (window.location.href = "/book")}
                className="inline-flex items-center justify-center px-6 py-3 border border-gray-300 
                          text-base font-medium rounded-md text-white bg-purple-700 hover:bg-purple-800
                          transition-colors duration-200 shadow-sm"
              >
                Book with Charm
                <ArrowRight className="w-5 h-5 ml-2" />
              </button>
            </div>
          </div>

          {/* Right Column - Three.js Scene */}
          <div className="relative hidden md:block h-96">
            <ThreeScene />
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default HeroSection;