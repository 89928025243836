import React from 'react';
import { Heart, Brain, Leaf, Users, Star, Shield, User, LucideAArrowDown } from 'lucide-react';
import ErmalindaPicture from "../assets/Ermalinda.jpeg";
import LucindaPicture from "../assets/IMG_3163.JPEG"

const AboutSection = () => {
  return (
    <section id="about" className="bg-white py-16">
      <div className="container mx-auto px-4">
        {/* Team Introduction */}
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-4 text-blue-3">Our Mental Health Team</h2>
          <p className="text-lg text-gray-600 max-w-3xl mx-auto">
            At Transitions Behavioral Health, our team of specialized Psychiatric Mental Health Nurse Practitioners 
            provides compassionate, evidence-based care with a focus on holistic wellness and personalized treatment plans.
          </p>
        </div>

        {/* Lead Provider Section */}
        <div className="flex flex-col lg:flex-row items-center gap-12 mb-16">
          {/* Left side - Image and Specialties */}
          <div className="w-full lg:w-1/2">
            <div className="rounded-lg p-6 overflow-hidden shadow-xl">
              <img
                src="https://headway.co/cdn-cgi/image/width=500,quality=100,format=auto,fit=contain/https://assets.headway.co/provider_photos/99169/db5d7492-741e-11ef-8b92-0a58a9feac02-99169-1726486134775.jpeg"
                alt="Dr. Velma Henn"
                className="w-full h-full object-cover"
              />
            </div>
            <div className="mt-8 grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="p-4 bg-blue-50 rounded-lg text-center">
                <Heart className="w-8 h-8 mx-auto text-blue-600 mb-2" />
                <h3 className="text-lg font-semibold mb-1">Holistic Care</h3>
                <p className="text-sm text-gray-600">Integrative mental health approach</p>
              </div>
              <div className="p-4 bg-green-50 rounded-lg text-center">
                <Brain className="w-8 h-8 mx-auto text-green-600 mb-2" />
                <h3 className="text-lg font-semibold mb-1">Specialized Focus</h3>
                <p className="text-sm text-gray-600">Hormone & mental health expert</p>
              </div>
              <div className="p-4 bg-purple-50 rounded-lg text-center">
                <Leaf className="w-8 h-8 mx-auto text-purple-600 mb-2" />
                <h3 className="text-lg font-semibold mb-1">Lifestyle Integration</h3>
                <p className="text-sm text-gray-600">Nutrition & wellness emphasis</p>
              </div>
            </div>
          </div>

          {/* Right side - Text Content */}
          <div className="w-full lg:w-1/2">
            <div className="flex items-center mb-4">
              <h2 className="text-3xl font-bold text-blue-2">Velma Henn, PMHNP</h2>
              <span className="ml-3 px-3 py-1 bg-blue-1 bg-opacity-20 text-blue-2 text-sm font-semibold rounded-full">Lead Provider</span>
            </div>
            <div className="space-y-4 text-gray-600">
              <p className="leading-relaxed">
                Welcome! I'm Velma Henn, a Psychiatric Mental Health Nurse Practitioner with over 26 years of nursing experience and 4 years as a dedicated nurse practitioner. My journey in healthcare has been shaped by a commitment to holistic, compassionate care that addresses not only mental health challenges but also the lifestyle factors that contribute to overall well-being.
              </p>
              
              <p className="leading-relaxed">
                At Transitions Behavioral Health, I specialize in treating adolescents, adults, and professionals, with a particular focus on integrating holistic practices, nutritional psychiatry, and evidence-based therapies like Cognitive Behavioral Therapy (CBT). My goal is to help clients overcome mental health challenges by optimizing both physical and mental health, blending traditional treatment options with a focus on diet, nutrition, and lifestyle.
              </p>

              <p className="leading-relaxed">
                Additionally, I have a specialized interest in the mental health impacts of menopause and hormone replacement therapy (HRT), recognizing how hormonal shifts can play a pivotal role in emotional well-being. Whether we're addressing anxiety, depression, bipolar disorder, or insomnia, I aim to provide personalized, empathetic care that meets each client's unique needs.
              </p>

              <p className="leading-relaxed">
                Outside of work, you'll often find me walking my three Australian Shepherds or fishing – activities that keep me grounded and remind me of the importance of balance in life. I look forward to helping you on your journey toward mental wellness with a comprehensive approach designed just for you.
              </p>
            </div>
          </div>
        </div>

        {/* Additional Providers */}
        <h3 className="text-2xl font-bold mb-8 text-blue-2">Our Team of Specialists</h3>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
          {/* Ermalinda */}
          <div className="flex flex-col md:flex-row gap-6 bg-blue-4 bg-opacity-5 rounded-lg p-6 shadow-md">
            <div className="w-full md:w-1/3">
              <div className="rounded-lg overflow-hidden shadow-md mb-4">
                <img
                  src={ErmalindaPicture}
                  alt="Ermalinda Salazar Mendez"
                  className="w-full h-64 object-cover"
                />
              </div>
              <div className="space-y-2">
                <div className="p-2 bg-blue-50 rounded text-center">
                  <Users className="w-6 h-6 mx-auto text-blue-600 mb-1" />
                  <p className="text-xs text-gray-600">Family Care</p>
                </div>
                <div className="p-2 bg-green-50 rounded text-center">
                  <Brain className="w-6 h-6 mx-auto text-green-600 mb-1" />
                  <p className="text-xs text-gray-600">CBT Focus</p>
                </div>
                <div className="p-2 bg-purple-50 rounded text-center">
                  <Leaf className="w-6 h-6 mx-auto text-purple-600 mb-1" />
                  <p className="text-xs text-gray-600">Holistic</p>
                </div>
              </div>
            </div>
            <div className="w-full md:w-2/3">
              <h4 className="text-xl font-bold mb-3 text-blue-2">Ermalinda Salazar Mendez, PMHNP</h4>
              <div className="space-y-3 text-gray-600">
                <p className="leading-relaxed">
                  With over 25 years of nursing experience, including 2 years focused on mental health care, Ermalinda brings a wealth of knowledge to her practice with adolescents, adults, and professionals.
                </p>
                <p className="leading-relaxed">
                  She specializes in a holistic approach, integrating Cognitive Behavioral Therapy (CBT) and lifestyle strategies to enhance both mental and physical health. Committed to compassionate, evidence-based care, Ermalinda collaborates with clients to create tailored plans designed for success.
                </p>
                <p className="leading-relaxed">
                  As a busy mom of four, she understands the challenges of balancing life's demands while prioritizing mental wellness, bringing both professional expertise and personal insight to her practice.
                </p>
              </div>
            </div>
          </div>

          {/* Lucy */}
          <div className="flex flex-col md:flex-row gap-6 bg-blue-4 bg-opacity-5 rounded-lg p-6 shadow-md">
            <div className="w-full md:w-1/3">
              <div className="rounded-lg overflow-hidden shadow-md mb-4 bg-blue-4 bg-opacity-20 flex items-center justify-center h-64">
                {/* Profile placeholder */}
                <img
                  src={LucindaPicture}
                  alt="Ermalinda Salazar Mendez"
                  className="w-full h-64 object-cover"
                />
              </div>
              <div className="space-y-2">
                <div className="p-2 bg-blue-50 rounded text-center">
                  <Star className="w-6 h-6 mx-auto text-blue-600 mb-1" />
                  <p className="text-xs text-gray-600">Medication</p>
                </div>
                <div className="p-2 bg-green-50 rounded text-center">
                  <Shield className="w-6 h-6 mx-auto text-green-600 mb-1" />
                  <p className="text-xs text-gray-600">Psychotherapy</p>
                </div>
                <div className="p-2 bg-purple-50 rounded text-center">
                  <Heart className="w-6 h-6 mx-auto text-purple-600 mb-1" />
                  <p className="text-xs text-gray-600">Compassionate</p>
                </div>
              </div>
            </div>
            <div className="w-full md:w-2/3">
              <h4 className="text-xl font-bold mb-3 text-blue-2">Lucinda Elizondo, PMHNP</h4>
              <div className="space-y-3 text-gray-600">
                <p className="leading-relaxed">
                  As a dedicated Psychiatric-Mental Health Nurse Practitioner, Lucy is passionate about weaving together the art and science of mental health care to provide comprehensive treatment for her patients.
                </p>
                <p className="leading-relaxed">
                  With exceptional assessment skills and diagnostic expertise, she offers personalized care through expertly tailored medication management and compassionate psychotherapy guidance. Lucy's approach emphasizes the connection between mental health and overall wellness.
                </p>
                <p className="leading-relaxed">
                  Her commitment to uplifting patients' spirits shines through in every interaction, creating a supportive environment where healing can flourish and patients can work toward achieving their wellness goals.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Team Approach */}
        <div className="bg-blue-2 bg-opacity-5 rounded-lg p-8 shadow-md border-l-4 border-blue-3">
          <h3 className="text-2xl font-bold mb-4 text-blue-2 text-center">Our Collaborative Approach</h3>
          <p className="text-gray-600 text-center mb-6">
            At Transitions Behavioral Health, our providers work together to ensure comprehensive, 
            personalized care for each client. Led by Velma Henn, our team combines decades of experience 
            with specialized expertise to address a wide range of mental health needs.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="bg-white p-4 rounded-lg text-center shadow-sm border-t-2 border-blue-3">
              <h4 className="font-semibold text-lg mb-2 text-blue-2">Personalized Treatment</h4>
              <p className="text-gray-600 text-sm">Tailored care plans addressing your unique needs and goals</p>
            </div>
            <div className="bg-white p-4 rounded-lg text-center shadow-sm border-t-2 border-blue-3">
              <h4 className="font-semibold text-lg mb-2 text-blue-2">Evidence-Based Care</h4>
              <p className="text-gray-600 text-sm">Combining proven therapies with innovative approaches</p>
            </div>
            <div className="bg-white p-4 rounded-lg text-center shadow-sm border-t-2 border-blue-3">
              <h4 className="font-semibold text-lg mb-2 text-blue-2">Holistic Wellness</h4>
              <p className="text-gray-600 text-sm">Addressing mental health alongside physical and lifestyle factors</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;