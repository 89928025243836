// src/hooks/useChatBotRouteWatcher.jsx
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useChatBot } from '../context/ChatBotContext';

export const useChatBotRouteWatcher = () => {
  const location = useLocation();
  const { showChatBot, hideChatBot } = useChatBot();
  
  useEffect(() => {
    // Only show on home page and book page
    if (location.pathname === '/' || location.pathname === '/book') {
      showChatBot();
    } else {
      hideChatBot();
    }
  }, [location.pathname, showChatBot, hideChatBot]);
  
  // No return value needed as this is just watching for changes
  return null;
};

export default useChatBotRouteWatcher;