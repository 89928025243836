// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';

// Components
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import CharmBot from './components/CharmBot';
import HeroSection from './components/HeroSection';
import AboutSection from './components/AboutSection';
import InsuranceSection from './components/InsuranceSection';
import PartnershipSection from './components/PartnershipSection';
import ContactForm from './components/ContactForm';
import PrivacyPolicy from './components/PrivacyPolicy';
import RouteWatcher from './components/RouteWatcher';

// Pages
import BlogPage from './pages/BlogPage';
import BookPage from './pages/BookPage';
import FAQPage from './pages/FAQPage';
import ResourcesPage from './pages/ResourcesPage';

// Context
import { ChatBotProvider } from './context/ChatBotContext';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        
        <Routes>
          <Route path="/" element={
            <>
              <HeroSection />
              <AboutSection />
              <InsuranceSection />
              <PartnershipSection />
              <ContactForm />
            </>
          } />
          <Route path="/blogs" element={<BlogPage />} />
          <Route path="/book" element={<BookPage />} />
          <Route path="/faqs" element={<FAQPage />} />
          <Route path="/resources" element={<ResourcesPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
        
        <ChatBotProvider>
          <RouteWatcher />
          <CharmBot />
        </ChatBotProvider>
        
        <Footer />
      </div>
    </Router>
  );
}

export default App;