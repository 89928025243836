// src/components/CharmBot.jsx
import React, { useEffect } from 'react';
import { useChatBot } from '../context/ChatBotContext';

const CharmBot = () => {
  const { isChatBotVisible } = useChatBot();
  
  useEffect(() => {
    // Only load the script when the component is visible
    if (isChatBotVisible) {
      // Clean up any existing elements first
      const existingScript = document.getElementById('charmbot-script');
      const existingContainer = document.getElementById('charmbot-container');
      
      if (existingScript) existingScript.remove();
      if (existingContainer) existingContainer.remove();
      
      // Create script element
      const script = document.createElement('script');
      script.id = 'charmbot-script';
      script.type = 'text/javascript';
      script.async = true;
      
      // Set up configuration before loading the script
      window.charmbot = window.charmbot || {};
      window.charmbot.domain = "https://ehr.charmtracker.com";
      window.charmbot.theme = "redgreentheme";
      window.charmbot.description = "Welcome!";
      window.charmbot.introduction = "I am your virtual appointment bot to assist you in booking appointment.";
      window.charmbot.info = "Hello! To book an appointment, Click here.";
      
      // Set the script source last, after configuration
      script.src = "https://ehr.charmtracker.com/charmbot.do?method=startBot&client_id=ad5573370f326a1a3587abb7b7fb824fca4bb2bc6112cbf7968b0c45d88e1037";
      
      // Add the script to the document
      document.body.appendChild(script);
      
      // Add a style to override any overlay behavior
      const style = document.createElement('style');
      style.id = 'charmbot-style-fix';
      style.textContent = `
        /* Fix any overlays */
        [id^="charmbot"] div[style*="position: fixed"][style*="top: 0px"][style*="left: 0px"][style*="width: 100%"][style*="height: 100%"] {
          background-color: transparent !important;
          pointer-events: none !important;
        }
        
        /* Allow interaction with the chatbot itself */
        [id^="charmbot"] iframe,
        [id^="charmbot"] button,
        [id^="charmbot"] .chatbot-button {
          pointer-events: auto !important;
        }
      `;
      document.head.appendChild(style);
      
      return () => {
        // Clean up on unmount
        if (document.getElementById('charmbot-script')) {
          document.getElementById('charmbot-script').remove();
        }
        if (document.getElementById('charmbot-style-fix')) {
          document.getElementById('charmbot-style-fix').remove();
        }
        
        // Try to remove any elements created by the script
        const chatbotElements = document.querySelectorAll('[id^="charmbot"]');
        chatbotElements.forEach(el => el.remove());
      };
    }
  }, [isChatBotVisible]);
  
  // This component doesn't render anything directly
  return null;
};

export default CharmBot;