import React from 'react';
import aetna from '../assets/insurance/Aetna-Logo.png'
import bcbs from '../assets/insurance/bcbs-tx.png'
import cigna from '../assets/insurance/Cigna-Logo.png'
import quest from '../assets/insurance/quest.png'
import united from '../assets/insurance/United-Healthcare-Logo.png'

const InsuranceSection = () => {
  // Insurance data
  const insurances = [
    { name: "Blue Cross Blue Shield of Texas", image: bcbs },
    { name: "Aetna", image: aetna },
    { name: "United Healthcare", image: united },
    { name: "Cigna", image: cigna },
    { name: "Quest", image: quest },
  ];

  return (
    <section className="py-16 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="text-center mb-8">
          <div className="flex justify-center items-center mb-3">
            <div className="h-1 w-12 bg-blue-1"></div>
            <div className="h-1 w-12 bg-blue-4 mx-1"></div>
            <div className="h-1 w-12 bg-blue-2"></div>
          </div>
          <h2 className="text-3xl font-bold text-blue-3">Accepted Insurance Plans</h2>
          <p className="text-gray-600 mt-3 max-w-2xl mx-auto">
            We work with major insurance providers to ensure accessible mental health care for our community
          </p>
        </div>
        
        <div className="bg-white rounded-lg shadow-md py-10 px-6">
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-10 justify-items-center">
            {insurances.map((insurance, index) => (
              <div 
                key={index} 
                className="flex flex-col items-center"
              >
                <div className="mb-4 p-4 bg-white rounded flex items-center justify-center h-20">
                  <img
                    src={insurance.image}
                    alt={`${insurance.name} logo`}
                    className="max-h-16 max-w-full object-contain grayscale hover:grayscale-0 transition-all duration-300"
                  />
                </div>
                <span className="text-sm text-gray-700 text-center font-medium">{insurance.name}</span>
              </div>
            ))}
          </div>
        </div>

        <div className="mt-8 bg-blue-50 border-l-4 border-blue-1 rounded-lg p-5 max-w-3xl mx-auto flex items-center">
          <div className="bg-blue-1 text-white p-3 rounded-full mr-4">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
            </svg>
          </div>
          <div>
            <p className="text-gray-700 font-medium">
              Don't see your insurance? Email us at{' '}
              <a 
                href="mailto:info@transitionsbehavioralhealth.com" 
                className="text-blue-2 hover:underline"
              >
                info@transitionsbehavioralhealth.com
              </a>
              {' '}to verify coverage.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InsuranceSection;