import React, { useState, useEffect } from 'react';
import { Menu, X, UserCircle } from 'lucide-react';
import { useLocation } from 'react-router-dom';
import logo from '../assets/TransitionsBH.png';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setHasScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const navItems = [
    { name: 'Home', path: '/' },
    // { name: 'About', path: '/#about' },
    { name: 'Resources', path: '/resources' },
    { name: 'FAQs', path: '/faqs' },
    { name: 'Blogs', path: '/blogs' },
    { name: 'Book', path: '/book' }
  ];

  const isActive = (path) => {
    if (path.includes('#')) {
      // For hash links, check if we're on the home page and the hash matches
      return location.pathname === '/' && location.hash === path.split('#')[1];
    }
    // For regular routes, just check if the pathname matches
    return location.pathname === path;
  };

  return (
    <>
      {/* Spacer div to prevent content jump when navbar becomes fixed */}
      <div className="h-16"></div>
      
      <nav className={`bg-white fixed top-0 left-0 right-0 z-50 transition-all duration-200 
        ${hasScrolled ? 'shadow' : 'shadow-sm'}`}>
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex justify-between h-16">
            {/* Logo and Brand Name */}
            <div className="flex items-center">
              <div  className="items-center">
                <img
                  src={logo}
             
                  alt="Transitions Behavioral Health Logo"
                  className={`transition-all duration-200 h-32 w-auto py-4`}
                />
              </div>
            </div>

            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center">
              <div className="ml-10 flex items-baseline space-x-4">
                {navItems.map((item) => (
                  <a
                    key={item.name}
                    href={item.path}
                    className={`text-gray-600 px-3 py-2 text-sm font-medium transition-all duration-200
                      ${isActive(item.path) 
                        ? 'border-2 border-blue-2 bg-blue-2 rounded-md text-white' 
                        : 'hover:text-blue-2 relative after:absolute after:bottom-0 after:left-0 after:w-full after:h-0.5 after:bg-blue-2 after:scale-x-0 hover:after:scale-x-100 after:transition-transform after:duration-200'
                      }`}
                  >
                    {item.name}
                  </a>
                ))}
                
                {/* Patient Portal Link */}
                <a
                  href="https://phr.charmtracker.com/login.sas?FACILITY_ID=3a3047be28b32bf924b8ea188394dbb8edb5a5bb29aedb5a98ee2e5ce9d5cc8ac81b440bc8a53a00"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center text-blue-2 px-3 py-2 text-sm font-medium bg-blue-1 bg-opacity-10 rounded-md hover:bg-opacity-20 transition-all duration-200"
                >
                  <UserCircle className="w-4 h-4 mr-2" />
                  Patient Portal
                </a>
              </div>
            </div>

            {/* Mobile menu button */}
            <div className="md:hidden flex items-center">
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-600 
                  hover:text-blue-600 focus:outline-none transition-colors duration-200"
              >
                {isMenuOpen ? (
                  <X className="h-6 w-6" />
                ) : (
                  <Menu className="h-6 w-6" />
                )}
              </button>
            </div>
          </div>
        </div>

        {/* Mobile Navigation */}
        <div className={`md:hidden transition-all duration-200 ${isMenuOpen ? 'max-h-96' : 'max-h-0'} overflow-hidden`}>
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 bg-white shadow-lg">
            {navItems.map((item) => (
              <a
                key={item.name}
                href={item.path}
                className={`block px-3 py-2 rounded-md text-base font-medium transition-all duration-200
                  ${isActive(item.path)
                    ? 'border-2 border-blue-2 text-blue-2'
                    : 'text-gray-600 hover:text-blue-2 hover:bg-gray-50'
                  }`}
                onClick={() => setIsMenuOpen(false)}
              >
                {item.name}
              </a>
            ))}
            
            {/* Patient Portal Link for Mobile */}
            <a
              href="https://phr.charmtracker.com/login.sas?FACILITY_ID=3a3047be28b32bf924b8ea188394dbb8edb5a5bb29aedb5a98ee2e5ce9d5cc8ac81b440bc8a53a00"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center px-3 py-2 rounded-md text-base font-medium bg-blue-1 bg-opacity-10 text-blue-2 hover:bg-opacity-20 transition-all duration-200"
              onClick={() => setIsMenuOpen(false)}
            >
              <UserCircle className="w-5 h-5 mr-2" />
              Patient Portal
            </a>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;