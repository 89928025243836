// src/context/ChatBotContext.jsx
import React, { createContext, useContext, useState, useCallback } from 'react';

// Create context
const ChatBotContext = createContext();

// Custom hook to use the context
export const useChatBot = () => {
  return useContext(ChatBotContext);
};

// Provider component
export const ChatBotProvider = ({ children }) => {
  const [isChatBotVisible, setIsChatBotVisible] = useState(false);
  
  // Create memoized functions to show/hide the chatbot
  const showChatBot = useCallback(() => {
    setIsChatBotVisible(true);
  }, []);
  
  const hideChatBot = useCallback(() => {
    setIsChatBotVisible(false);
  }, []);
  
  // Value to be provided
  const value = {
    isChatBotVisible,
    setIsChatBotVisible,
    showChatBot,
    hideChatBot
  };
  
  return (
    <ChatBotContext.Provider value={value}>
      {children}
    </ChatBotContext.Provider>
  );
};