import React from 'react';
import useChatBotRouteWatcher from '../hooks/useChatBotRouteWatcher';



const RouteWatcher = () => {
  // This component just uses the hook and doesn't render anything
  useChatBotRouteWatcher();
  
  return null;
};

export default RouteWatcher;